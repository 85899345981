<template>
  <div class="main_page" style="display: flex; gap: 35px; padding-top: 120px; padding-bottom: 25px">
    <div class="image-container" style="border-radius: 36px 36px 36px 36px!important;">
      <img style="" src="../images/fon_first_page.png">
    </div>
    <q-card-section class="input_options" style="padding: 0; width: 50%">
      <h4>Заполните данные</h4>

      <q-select
          class="input"
          v-model="city"
          :options="cities"
          label="Филиал"
          filled
          use-input
          input-debounce="300"
          @filter="filterCity"
          :rules="[val => !!val || 'Филиал обязателен']"
      />

      <q-select
          class="input"
          v-model="selectedDepartment"
          :options="filteredDepartments"
          label="Функциональный департамент"
          filled
          use-input
          input-debounce="300"
          @filter="filterDepartment"
          :rules="[val => !!val || 'Департамент обязателен']"
      />

      <!-- Поле для ФИО -->
      <q-input
          class="input"
          v-model="fullName"
          label="Фамилия Имя Отчество"
          filled
          lazy-rules
          :rules="[val => !!val || 'ФИО обязательно для заполнению']"
      />

      <!-- Поле для описания услуги или продукта -->
      <q-input
          class="input"
          v-model="supportRequest"
          label="С каким вопросом обращались в Административный департамент?"
          filled
          type="textarea"
          lazy-rules
          :rules="[val => !!val || 'Вопрос обязателен к заполнению']"
      />
    </q-card-section>
  </div>
</template>

<script setup>
import {defineEmits, onBeforeMount, ref, watch} from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  citiesAndCompanies: Array,
  departmentsAndSections: Array
});
// Создаём переменные для каждого поля
const city = ref('')
const allCities = props.citiesAndCompanies.map(item => item.city.trim());
const cities = ref(allCities);
const department = ref('');
const fullName = ref('');
const supportRequest = ref('');
const emit = defineEmits(['save']);
const selectedDepartment = ref('')
const filteredDepartments = ref([])
const departmentsAndSections = ref(props.departmentsAndSections);
const section = ref('')

// Валидация и обновление формы
const validateForm = () => {
  errors.value = {
    city: !city.value ? 'Филиал обязателен' : '',
    selectedDepartment: filteredDepartments.value.length > 0 && !selectedDepartment.value ? 'Департамент обязателен' : '',
  };
  return Object.values(errors.value).every(error => !error);
};

const filterCity = (val, update) => {
  update(() => {
    cities.value = props.citiesAndCompanies
        .map(item => item.city.trim())
        .filter(option => option.toLowerCase().includes(val.toLowerCase()))
        .sort((a, b) => a.localeCompare(b));
  });
};


const updateFormData = () => {
  const isValid = validateForm();
  const formData = {
    branch: city.value,
    department: selectedDepartment.value,
    fullName: fullName.value,
    supportRequest: supportRequest.value
  };
  emit('save', {userInfo: formData, isValid});
};

const errors = ref({
  company: '',
  city: '',
  selectedDepartment: '',
  section: '',
  experience: '',
  age: ''
});

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  return decodeURIComponent(params.get('company'));
};

onBeforeMount(() => {
  const companyParam = getQueryParams();
  if (companyParam !== 'null') {
    updateFormData();
  }
});


const filterDepartment = (val, update) => {
  section.value = '';
  update(() => {
    filteredDepartments.value = departmentsAndSections.value
        .map(item => item.department)
        .filter(department => department.toLowerCase().includes(val.toLowerCase()))
        .sort((a, b) => a.localeCompare(b));
  });
};

watch([city, department, fullName, supportRequest], updateFormData);
</script>

<style scoped>
.input {
  padding-bottom: 25px;
}

.image-container {
  border-radius: 0 36px 36px 0 !important;
  background-color: #fdf8f7;
  padding: 25px;
  display: flex;
  justify-content: center;
  max-height: 640px;
  width: 50vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 756px) {
  .main_page {
    padding: 10px !important;
    width: 100%;
  }

  .image-container {
    display: none;
  }

  .q-card__section {
    width: 100% !important;
  }
}
</style>
