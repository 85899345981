import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import './styles.css'
import VueLazyload from 'vue-lazyload';




const configUrl = 'https://aptrade.ru';

const app = createApp(App);

app.config.globalProperties.$configUrl = configUrl;
app.use(Quasar, quasarUserOptions).mount('#app');
app.use(VueLazyload, {
    preLoad: 1.3,
    error: 'error.png',
    loading: 'loading.gif',
    attempt: 1,
    cache: true,  // кэширование включено
});
