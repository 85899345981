<template>
  <div class="app">
    <div v-if="isLoading" class="loading-screen">
      <q-spinner color="primary" size="100px" style="margin-bottom: 30px" />
      <p>Загрузка анкеты, пожалуйста подождите...</p>
    </div>

    <div v-if="!isLoading && currentQuestion === -1" class="main_page"  style="display: flex; gap: 35px; padding-top: 120px">
      <div class="image-container" style="">
        <img style="" src="./images/fon_first_page.png">
      </div>
      <div>
        <h3 style="margin-top: 0; font-weight: 500;">Дорогой коллега!</h3>
        <div class="text">
          <p style="font-weight: 600; line-height: 100%; font-size: 24px; ">Приглашаем тебя принять участие в опросе по административной службе.</p>
          <p style="margin-bottom: 15px; font-size: 24px; line-height: 100%; font-weight: 600;">Твои ответы помогут нам:</p>
          <ul style="margin: 0 0 15px; padding-left: 0;  list-style-type: none;  font-size: 24px; font-weight: 600; line-height: 100%">
            <li style="font-size: 24px; font-weight: 600">— Определить области для улучшения</li>
          </ul>
          <q-btn color="primary" style="font-size: 24px; font-weight: 600; color: #1184b8; border-radius: 16px; padding: 15px 8px" @click="handleStartSurvey()">Пройти опрос</q-btn>
        </div>
      </div>
    </div>
    <div v-if="currentQuestion === 0" style="padding: 10px; height: 100vh;">
      <q-card class="q-pa-md first_page" style="box-shadow: unset!important; background-color: var(--main-bg-color);flex-direction: column">
        <UserInfo @save="saveUserInfo" :cities-and-companies="citiesAndCompanies" :departments-and-sections="departmentsAndSections" />
        <q-btn
            :disabled="!isUserInfoValid"
            color="primary"
            style="font-size: 24px; font-weight: 600; color: #1184b8; border-radius: 16px; padding: 15px 8px"
            @click="startQuestions()"
        >
          Перейти к вопросам
        </q-btn>
      </q-card>
    </div>
    <div v-if="questions.length + 2 === currentQuestion"  class="main_page"  style="display: flex; gap: 35px; padding-top: 120px">
      <div class="image-container">
        <img style="" src="./images/fon_first_page.png">
      </div>
      <div >
        <h3 style="margin-top: 0; font-weight: 500;">Дорогой коллега!</h3>
        <div class="text">
          <p style="font-weight: 600; line-height: 100%; font-size: 32px; ">Спасибо за участие!</p>
          <p style="margin-bottom: 15px; font-size: 32px; line-height: 100%; font-weight: 600;">Каждый ответ поможет сделать нашу компанию и рабочую среду ещё лучше.</p>
        </div>
      </div>
    </div>
    <div class="container" v-if="currentQuestion >= 1">
      <transition name="fade" mode="out-in">
        <component
            :key="currentQuestion"
            v-if="currentComponent"
            :is="currentComponent"
            class="component"
            :question="questions[currentQuestion - 1]"
            :selected-answer="answers[currentQuestion - 1]"
            @next="handleNextAnswer"
            @prev="previousQuestion"
        />
      </transition>
      <div class="last_blocks" v-if="questions.length + 1 === currentQuestion">
        <h4>Есть ли какие-то предложения или замечания по улучшению работы службы технической поддержки?</h4>
        <textarea v-model="suggestion" placeholder="Введите ваше предложение..." style="width: 100%; height: 150px; padding: 10px; border-radius: 8px; border: 1px solid #ddd; resize: none;"></textarea>
      </div>
    </div>


    <footer v-if="!isLoading && currentQuestion !== -1 && currentQuestion !== 0 && questions.length + 2 !== currentQuestion">
      <q-card-actions v-if="currentQuestion >= 1" style="    max-width: 1200px; margin: auto; display: flex; flex-wrap: nowrap; position: relative; top: -60px;" align="left">
        <q-btn style="width: 100%" color="primary" v-if="currentQuestion" @click="currentQuestion--">Назад</q-btn>
        <q-btn
            v-if="currentQuestion === questions.length + 1"
            color="primary"
            style="width: 100%"
            @click="submitSurvey"
        >
          Завершить
        </q-btn>
      </q-card-actions>
      <div class="footer">
        <div class="progress-bar" :style="{ width: progressWidth }"></div>
        <span style="font-size: 16px; font-weight: 400">Вопрос {{ currentQuestion }} из {{ questions.length + 1 }}</span>
      </div>
    </footer>
    <noscript>
      <div>
        <img
            src="https://mc.yandex.ru/watch/98683341"
            style="position:absolute; left:-9999px;"
            alt=""
        />
      </div>
    </noscript>
  </div>
</template>

<script setup>
import {computed, onBeforeMount, watch} from 'vue'
import { getCurrentInstance } from 'vue';
const { proxy } = getCurrentInstance();

import { ref } from 'vue'
import FourSquare from "@/components/FourSquare.vue";
import UserInfo from "@/components/UserInfo.vue";


import axios from "axios";
import {Loading, Notify} from "quasar";


const selectedOptions = ref([]);


const questions = ref([{
  type: 'Четыре квадрата',
  text: "Как быстро служба административной поддержки реагировала на ваш запрос?",
  options: [
    {question: "Как быстро служба административной поддержки реагировала на ваш запрос?", label: "Отлично", img: require("./images/q1/Asset 4_transpBG.png"), val: 4},
    {question: "Как быстро служба административной поддержки реагировала на ваш запрос?", label: "Хорошо", img: require("./images/q1/Asset 3_transpBG.png"), val: 3},
    {question: "Как быстро служба административной поддержки реагировала на ваш запрос?", label: "Удовлетворительно", img: require("./images/q1/Asset 2_transpBG.png"), val: 2},
    {question: "Как быстро служба административной поддержки реагировала на ваш запрос?", label: "Неудовлетворительно", img: require("./images/q1/Asset 1_transpBG.png"), val: 1}
  ]
}, {
  type: 'Четыре квадрата',
  text: "Вы использовали электронный журнал для заявки в Административную службу?",
  options: [
    {question: "Вы использовали электронный журнал для заявки в Административную службу?", label: "Использовал", img: require("./images/q2/Asset 4_transpBG.png"), val: 4},
    {question: "Вы использовали электронный журнал для заявки в Административную службу?", label: "Не использовал ", img: require("./images/q2/Asset 3_transpBG.png"), val: 3},
    {question: "Вы использовали электронный журнал для заявки в Административную службу?", label: "На филиале нет такого ", img: require("./images/q2/Asset 2_transpBG.png"), val: 2},
    {question: "Вы использовали электронный журнал для заявки в Административную службу?", label: "Не понимаю, о чём речь", img: require("./images/q2/Asset 1_transpBG.png"), val: 1}
  ]
}, {
  type: 'Четыре квадрата',
  text: "Оцените температурный режим на рабочем месте.",
  options: [
    {question: "Оцените температурный режим на рабочем месте.", label: "Отлично", img: require("./images/q3/Asset 4_transpBG.png"), val: 4},
    {question: "Оцените температурный режим на рабочем месте.", label: "Хорошо", img: require("./images/q3/Asset 3_transpBG.png"), val: 3},
    {question: "Оцените температурный режим на рабочем месте.", label: "Удовлетворительно", img: require("./images/q3/Asset 2_transpBG.png"), val: 2},
    {question: "Оцените температурный режим на рабочем месте.", label: "Неудовлетворительно", img: require("./images/q3/Asset 1_transpBG.png"), val: 1}
  ]
}, {
  type: 'Четыре квадрата',
  text: "Оцените качество уборки кабинетов, территории ",
  options: [
    {question: "Оцените качество уборки кабинетов, территории.", label: "Отлично", img: require("./images/q4/Asset 4_transpBG.png"), val: 4},
    {question: "Оцените качество уборки кабинетов, территории.", label: "Хорошо", img: require("./images/q4/Asset 3_transpBG.png"), val: 3},
    {question: "Оцените качество уборки кабинетов, территории.", label: "Удовлетворительно", img: require("./images/q4/Asset 2_transpBG.png"), val: 2},
    {question: "Оцените качество уборки кабинетов, территории.", label: "Неудовлетворительно", img: require("./images/q4/Asset 1_transpBG.png"), val: 1}
  ]
}, {
  type: 'Четыре квадрата',
  text: "Оцените качество освещения рабочего места.",
  options: [
    {question: "Оцените качество освещения рабочего места.", label: "Отлично", img: require("./images/q5/Asset 4_transpBG.png"), val: 4},
    {question: "Оцените качество освещения рабочего места.", label: "Хорошо", img: require("./images/q5/Asset 3_transpBG.png"), val: 3},
    {question: "Оцените качество освещения рабочего места.", label: "Удовлетворительно", img: require("./images/q5/Asset 2_transpBG.png"), val: 2},
    {question: "Оцените качество освещения рабочего места.", label: "Неудовлетворительно", img: require("./images/q5/Asset 1_transpBG.png"), val: 1}
  ]
}, {
  type: 'Четыре квадрата',
  text: "Каково ваше общее удовлетворение работой административной службы?",
  options: [
    {question: "Каково ваше общее удовлетворение работой административной службы?", label: "Отлично", img: require("./images/q6/Asset 4_transpBG.png"), val: 4},
    {question: "Каково ваше общее удовлетворение работой административной службы?", label: "Хорошо", img: require("./images/q6/Asset 3_transpBG.png"), val: 3},
    {question: "Каково ваше общее удовлетворение работой административной службы?", label: "Удовлетворительно", img: require("./images/q6/Asset 2_transpBG.png"), val: 2},
    {question: "Каково ваше общее удовлетворение работой административной службы?", label: "Неудовлетворительно", img: require("./images/q6/Asset 1_transpBG.png"), val: 1}
  ]
}]);
const isLoading = ref(false);
const suggestion = ref('');
const isUrlValid = ref(true);



const citiesAndCompanies = ref(undefined)
const departmentsAndSections = ref(undefined)
const currentQuestion = ref(-1);
const answers = ref({});
const userInfo = ref({});
const isUserInfoValid = ref(false);
const surveyStartTime = ref(null);
const surveyDuration = ref(null);


const fetchQuestions = async () => {
  try {
    const responseCities = await axios.get(`${proxy.$configUrl}/api/anketa-vovlechyonnosti-gorodas`, {
      params: {
        'pagination[pageSize]': 200
      }
    });
    const responseDepartments = await axios.get(`${proxy.$configUrl}/api/anketa-vovlechyonnosti-pasportichkas`, {
      params: {
        'pagination[pageSize]': 500
      }
    });

    const dataCities = responseCities.data;
    const dataDepartments = responseDepartments.data;


    citiesAndCompanies.value = dataCities.data.map((item) => ({
      city: item.attributes.City,
      companies: item.attributes.Companies.split(', ').filter(Boolean),
    }));
    departmentsAndSections.value = ref(dataDepartments.data.map((item) => ({
      company: item.attributes.Companies,
      department: item.attributes.department,
      sections: item.attributes.otdel ? item.attributes.otdel.split('; ').filter(Boolean) : []
    })));
    isLoading.value = false;
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
    isLoading.value = false;
  }
};

const saveUserInfo = (data) => {
  userInfo.value = data;
  isUserInfoValid.value = data.isValid;
};

const handleStartSurvey = () => {
  surveyStartTime.value = new Date();
  nextQuestion();

};
const startQuestions = () => {
  surveyStartTime.value = new Date();
  currentQuestion.value = 1;
};


const isCheckboxValid = ref(false);

const currentComponent = computed(() => {
  if (currentQuestion.value === 0) return null; // Для экрана с вступлением
  const type = questions.value[currentQuestion.value - 1]?.type;
  switch (type) {
    case 'Четыре квадрата':
      return FourSquare;
    default:
      return null;
  }
});

const nextQuestion = () => {
  if (currentQuestion.value < questions.value.length + 2) {
    currentQuestion.value++;
  }
};

const handleNextAnswer = (answer) => {
  answers.value[currentQuestion.value - 1] = answer;
  nextQuestion();
};

const previousQuestion = () => {
  if (currentQuestion.value > 1) {
    currentQuestion.value--;
  }
};

const progressWidth = computed(() => {
  return `${(currentQuestion.value / questions.value.length) * 100}%`;
});

const calculateSurveyDuration = () => {
  const endTime = new Date();
  surveyDuration.value = Math.floor((endTime - surveyStartTime.value) / 1000); // Duration in seconds
};

const isSubmitting = ref(false);
const submitSurvey = async () => {
  isSubmitting.value = true;
  isUserInfoValid.value = false;
  Loading.show({
    message: 'Сохранение ответов, пожалуйста, подождите...',
    spinnerColor: 'indigo',
  });
  try {
    const payload = {
      data: {
        answers: {
          user_info: userInfo,
          metadata: {
            submission_time: new Date().toISOString(),
            duration: surveyDuration.value
          },
          data: {
            answers: [
              ...Object.keys(answers.value).map(key => ({
                question_id: key,
                answer: answers.value[key]
              })),
              {
                question_id: 'suggestion',
                answer: {
                  val: suggestion.value,
                  question: 'Есть ли какие-то предложения или замечания по улучшению работы службы технической поддержки?',
                }
              }
            ]
          }
        }
      }
    };

    console.log(JSON.stringify(userInfo.value));
    const payload_2 = new FormData();
    payload_2.append('fullName', userInfo.value.userInfo.fullName || 'Не указано');
    payload_2.append('branch', userInfo.value.userInfo.branch || 'Не указано');
    payload_2.append('department', userInfo.value.userInfo.department || 'Не указано');
    payload_2.append('question', userInfo.value.userInfo.supportRequest);
    payload_2.append(
        'answers',
        JSON.stringify(
            Object.keys(answers.value).map((key) => ({
              question_id: key,
              answer: answers.value[key],
            }))
        )
    );

    if (suggestion.value) {
      payload_2.append('suggestion', suggestion.value);
    }

    await axios.post(`${proxy.$configUrl}/api/oprosnik-po-administrativnoj-sluzhbes`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const response = await axios.post('https://survey.ipiton.ru/mail/send_form.php ', payload_2, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Ответ от PHP:', response.data);

    Notify.create({
      type: 'positive',
      message: 'Твои ответы сохранены! Спасибо, что прошёл опрос!',
      position: 'top',
      timeout: 3000,
    });

    currentQuestion.value++;
    calculateSurveyDuration();


  } catch (error) {
    console.error("Ошибка при сохранении ответов:", error);
    console.log("Ответ сервера:", error.response?.data);
    Notify.create({
      type: 'negative',
      message: 'Ошибка при отправке данных. Пожалуйста, попробуй снова. Если ошибка повториться, пожалуйста, пройди опрос повторно.',
      position: 'top',
      timeout: 30000,
      actions: [
        {
          label: 'Отправить снова',
          color: 'white',
          handler: () => submitSurvey()
        }
      ]
    });

  } finally {
    isSubmitting.value = false;
    Loading.hide();
  }
};

if (!isUrlValid.value) {
  Notify.create({
    type: 'negative',
    message: 'Уважаемый коллега, для прохождения теста необходимо использовать корректную ссылку',
    position: 'top',
    timeout: 5000,
  });
}

function decodeUrl(url) {
  // Двойное декодирование и удаление пробелов
  const decodedUrl = decodeURIComponent(decodeURIComponent(url)).replace(/\s+/g, '').replace(/%20/g, '');
  console.log(decodedUrl);
  return decodedUrl;
}

onBeforeMount(async () => {
  let currentUrl = window.location.href;

  await fetchQuestions();

  const companyNames = Array.from(
      new Set(
          citiesAndCompanies.value.flatMap(item => item.companies || [])
      )
  ).map(company => company.replace(/\s+/g, '').replace(/%20/g, ''));

  currentUrl = decodeUrl(currentUrl);

  // Проверка на наличие параметра "company" и совпадение с одним из имен в списке
  if (!currentUrl.includes("company") || !companyNames.some(company => currentUrl.includes(company))) {
    isUrlValid.value = false;
  }
});

watch(selectedOptions, (newValue) => {
  isCheckboxValid.value = newValue.length >= 1;
}, { immediate: true });

watch(currentQuestion, (newValue) => {
  if (newValue === -1) {
    surveyStartTime.value = null;
    surveyDuration.value = null;
  }
});


</script>

<style scoped>


input[type="checkbox"] {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}



.image-container {
  border-radius: 0 36px 36px 0!important;
  background-color: #fdf8f7;
  padding: 25px;
  display: flex;
  justify-content: center;
  max-height: 640px;
  width: 50vw;
  -moz-border-radius-bottomleft: 25px!important;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.first_page {
  display: flex;
  max-height: 1200px;
  height: 100vh;
}


.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.component {
  box-shadow: unset!important;
  background-color: var(--main-bg-color);
}

.text {
  max-width: 75%;
}

.app {
  width: 100vw;
  height: 100vh;
  background-color: var(--main-bg-color);
  position: relative;
  display: flex;
  flex-direction: column;
}

.container {
  margin: auto;
  max-width: 1200px;
  flex-grow: 1; /* Позволяет контейнеру занимать все доступное пространство */
}

.footer {
  background-color: #f3f3f3;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  height: 50px;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(25, 117, 209, 0.4);
  z-index: -1;
  transition: width 0.4s ease;
}
.last_blocks {
  padding:  0 100px 100px 100px; max-width: 900px; margin: auto
}


@media (max-width: 756px) {
  .last_blocks {
    padding: 10px 10px 70px 10px ;
  }
  .main_page {
    padding: 10px!important;
  }
  .image-container {
    display: none;
  }
  .container {
    max-width: 95vw;
  }
  .first_page {
    padding: 0;
    height: 90vh;
    h3 {
      margin: 0;
      font-size: 32px;
      padding-bottom: 15px;
    }
  }
  .text {
    max-width: 100%;
  }
}
</style>
