<template>
  <q-card class="q-pa-md" style="padding-bottom: 75px">

    <h4 style="margin: 0; padding-bottom: 20px">{{ question.text }}</h4>
    <q-card-actions>
      <div class="q-gutter-sm">
        <q-btn
          v-for="(option, index) in question.options"
          :key="index"
          @click="selectOption(option)"
          class="answer-option"
          :class="{ 'selected': selectedOption?.label === option.label }"
        >
          <div class="answer-content">
              <img :src="`${option.img}`" alt="" class="answer-img">
            <span>{{ option.label }}</span>
          </div>
        </q-btn>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  question: Object,
  selectedAnswer: Object
})
const emit = defineEmits(['next', 'prev'])

const selectedOption = ref(null)

watch(() => props.selectedAnswer, (newVal) => {
  if (newVal) {
    selectedOption.value = newVal
  }
}, { immediate: true })

const selectOption = (option) => {
  selectedOption.value = option
  emit('next', option)
}
</script>

<style scoped>
.answer-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  img {
    width: 100%;
    max-width: 100%;
    height: 30vh;
  }
  span {
    text-transform: none;
    text-align: left;
  }
}
span {
  font-size: 20px;
  font-weight: 400;
  color: #3f3f3f;
}

.answer-img {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  box-sizing: border-box;
}
.q-card__actions .q-btn {
  background-color: #dbdde1;
}
.q-gutter-sm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  height: auto;
}



@media (max-width: 756px) {
  .answer-content {
    img {
      width: 100%;
      height: auto;
    }
    span {
      font-size: 12px;
      line-height: 1.2rem;
      letter-spacing: 0.00735em;
    }
  }
}


@media (max-width: 360px) {
  .answer-content {
    img {
      width: 100%;
      height: auto;
    }

    span {
      font-size: 12px;
      line-height: 1.2rem;
      letter-spacing: 0.00735em;
    }
  }
}
</style>
